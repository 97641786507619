import React, { useState } from "react";
import { graphql, Link } from "gatsby";

import { FncsTable } from "../../components/FncsTable";
import novosBannerDesktopBg from "../../assets/novos-banner-desktop-bg.png";
import adsBannerDesktopBg from "../../assets/ads-banner-desktop-bg.png";
import novosBannerDesktopChar from "../../assets/novos-banner-desktop-char.png";
import novosBannerMobileChar from "../../assets/novos-banner-mobile-char.png";
import novosPinkLogo from '../../assets/novos-pink-logo.png';
import LogoSmall from "../../components/LogoSmall"
import LogoFull from "../../components/LogoFull"
import subscribeBanner1 from '../../assets/premium/subscribe-banner-1.png';
import subscribeBanner2 from '../../assets/premium/subscribe-banner-2.png';
import GradientButton from '../../components/gradient-button';

import * as styles from "./fncs.module.scss";
import Seo from "../../components/seo";

const FncsIndexPage = ({ data }) => {
  const regions = ['OCE', 'ASIA', 'ME', 'EU', 'BR', 'NAC'];
  // data.allContentfulFncsRegionLeaderboardFull.nodes.flatMap(
  //   (node) => node.region
  // );
  const [filterText, setFiterText] = useState("");

  //TO-DO use localstorage to save
  const [selectedRegion, setSelectedRegion] = useState(regions[0]);

  return (
    <div
      className={`${styles.fncs} flex justify-items-center flex-col items-center`}
    >
      <div className="w-full">
        <header>
          <h1>FNCS Series Leaderboards</h1>
        </header>
        <Seo
          description="Live FNCS Leaderboards by KinchAnalytics"
        />
        <p>
          {`Nothing in these leaderboards is official. It is our best attempt at following
the rules that Epic have given. If you are within a few places of qualifying,
then do not assume that you have/haven't qualified and we can't give you a
definite answer. Possible differences between this data and the official data
are:`}
        </p>
        <ul className="list-disc ml-6">
          <li>
            <p>{`Epic may disqualify people, may change their points etc.`}</p>
          </li>
          <li>
            <p>
              {`The official tie-breaker rules are ambiguous, so we try our best to interpret them.`}
            </p>
          </li>
        </ul>
        <p>
          Data should get from the game to this page within about 4 minutes.
          <br/>
          You can find out about the FNCS format and how series points work
          <Link className="underline text-ka-cyan ml-1" to="https://www.fortnite.com/competitive/news/fortnite-championship-series-2024-official-rules" target="_blank">here</Link>
          <br/>
          There is also a graphical explanation of the
          <Link className="underline text-ka-cyan ml-1" to="https://x.com/KinchAnalytics/status/1748029669780124149?s=20" target="_blank">2024 FNCS format here</Link>
        </p>
        {/* <p>
          If you want help during tournaments, then Kinch Premium is the perfect service for you. We have advice, points estimates, best dropspots, dropmaps and more.
          <Link className="underline text-ka-cyan ml-1" to="https://kinchanalytics.com/premium" target="_blank">Read about it here</Link>
        </p> */}
      </div>


      <div class="w-full relative sm:pt-8 overflow-hidden">
        <div className="w-full sm:w-auto p-px rounded-3xl subscribe-block">
          <div className="block rounded-3xl md:p-10">
            <div className="grid grid-cols-12 justify-between">
              <div className="col-span-4 hidden xl:block">
                <img className="absolute left-0 bottom-0" src={subscribeBanner1}/>
                        </div>
                        <div className="col-span-12 md:col-span-7 xl:col-span-4 w-full text-center">
                            <div className="text-3xl lg:text-5xl font-semibold">
                              Kinch Premium
                            </div>
                            <div className="mt-4 mb-6">
                              <p>The Essential Tool for Competitive Fortnite Players<br/>Advice, Points Estimates, Best Dropspots, Dropmaps, Bots and more!<br/>1-Week Free Trial for New Customers!</p>
                            </div>
                              <GradientButton className="h-12">
                                <a href="https://premium.kinchanalytics.com" target="_blank" class="px-8">Find Out More</a>
                              </GradientButton>
                        </div>
                        <div className="col-span-4 hidden md:block">
                          <img className="absolute bottom-0 right-0" src={subscribeBanner2}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>


      {/* <div
          className="relative w-full overflow-hidden mt-16 mb-8"
          style={{ height: 260 }}
        >
          <a className="rounded-md mt-2 cursor-pointer" href="https://lootcifer.com" target="_blank">
          <img
            className="h-full w-full object-cover rounded-xl"
            src={adsBannerDesktopBg}
            alt=""
          />
          </a>
        </div> */}

        
      <div className="w-full">
      {/* <div className="flex flex lg:flex-row justify-end items-center" style={{ marginBottom: -35}} >
          <div style={{ fontSize: 12 }}> Powred by</div>
            <a className="cursor-pointer" href="https://novos.gg/?utm=kinchanalytics_FNCSbanner" target="_blank">
              <img style={{ height: 15, paddingLeft: 10 }} src={novosPinkLogo} alt="NOVOS.GG" />
            </a>
          </div> */}
        <div className="flex flex-col lg:flex-row justify-between mt-8">
          <div className="flex-grow">
            <nav className="flex flex-row overflow-auto">
              {regions.map((region) => (
                <button
                  key={region}
                  className={`flex-grow py-4 px-6 block hover:text-ka-cyan ${
                    selectedRegion === region
                      ? "text-ka-cyan border-b-4 border-ka-cyan"
                      : ""
                  }`}
                  onClick={() => {
                    setSelectedRegion(region);
                  }}
                >
                  {region}
                </button>
              ))}
            </nav>
            <div
              style={{ height: 2 }}
              className="bg-white bg-opacity-20 -mt-0.5"
            ></div>
          </div>
          <div className="relative mt-3 ml-4">
            
            <svg
              className="absolute left-0 top-0 mt-4 ml-4 h-4 w-4 fill-current opacity-50"
              x="0px"
              y="0px"
              viewBox="0 0 56.966 56.966"
              width="512px"
              height="512px"
            >
              <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
            </svg>
            <input
              type="text"
              style={{
                textIndent: 20,
                border: "2px solid #42416B",
                background: "#26285D",
              }}
              placeholder="Search for a player"
              className="bg-transparent h-12 px-5 pr-10 rounded-full text-sm focus:outline-none w-full lg:w-auto"
              value={filterText}
              onChange={(event) => {
                setFiterText(event.target.value);
              }}
            />
          </div>
        </div>
        


        <div className="w-full max-h-screen h-4/6 overflow-auto relative">
          <FncsTable region={selectedRegion} filterText={filterText} />
        </div>
        <div
          className="relative w-full overflow-hidden mt-16 mb-8"
          // style={{ height: 260 }}
        >
          {/* <img
            className="h-full object-cover rounded-xl"
            src={novosBannerDesktopBg}
            alt=""
          />
          <img
            className="absolute top-0 right-0 h-full object-cover hidden sm:block"
            src={novosBannerDesktopChar}
            alt=""
          />
          <img
            className="absolute top-0 right-0 h-full object-cover sm:hidden"
            src={novosBannerMobileChar}
            alt=""
          /> */}

          {/* <div className="absolute top-0 left-0 flex flex-col h-40 p-5 sm:p-10">
            <LogoSmall className="sm:hidden"/>
            <LogoFull className="hidden sm:block left-0" style={{ width: 195 }}/>
            <span className="font-bold mt-3 text-3xl uppercase w-44 leading-8 sm:w-120 sm:text-4xl" style={{  fontFamily: 'rift' }}>
              become the new FNCS champion
              #NOVOSFNCS
            </span>
            <a className="rounded-md mt-2 cursor-pointer" href="https://novos.gg/?utm=kinchanalytics_FNCSbanner" target="_blank" style={{ fontWeight: 500, color: "#F4D8E9", backgroundColor: "#2CAD6D" }}>
              <div className="uppercase h-12 p-3 text-center" style={{ fontFamily: 'Roboto' }}>Start now!</div>
            </a>
          </div> */}
          
          {/* <div className="w-full relative sm:pt-8 overflow-hidden mb-10 sm:mb-20">
              <div className="w-full sm:w-auto p-px rounded-3xl subscribe-block">
                  <div className="block rounded-3xl md:p-10">
                      <div className="grid grid-cols-12 justify-between">
                          <div className="col-span-4 hidden xl:block">
                              <img className="absolute left-0 top-0" src={subscribeBanner1}/>
                          </div>
                          <div className="col-span-12 md:col-span-7 xl:col-span-4 w-full text-center">
                              <div className="text-3xl lg:text-5xl font-semibold">
                                Subscribe Now
                              </div>
                              <div className="mt-4 mb-6">
                                Kinch Premium is an Analytics Service provided for $8 per month.
                              </div>
                                <GradientButton className="h-12">
                                  <a href="https://upgrade.chat/kinchanalytics" target="_blank" class="px-8">Subscribe</a>
                                </GradientButton>
                          </div>
                          <div className="col-span-4 hidden md:block">
                            <img className="absolute top-0 right-0" src={subscribeBanner2}/>
                          </div>
                      </div>
                  </div>
              </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export const query = graphql`
  query FncsIndexPageQuery {
    allContentfulFncsRegionLeaderboardFull {
      nodes {
        id
        region
      }
    }
  }
`;

export default FncsIndexPage;
